@import "core/index.scss";
.form-feedback {
	margin-bottom: 2rem;
	line-height: 1.333em;
	border-radius: $radius-general;
	border: .1rem solid $color-gray;
	background-color: $color-bg;
	// padding: 1.5rem 1.5rem;
	padding: 1.9rem 1.5rem 2rem;
	font-size: 1.5rem;
	display: block;
	width: 100%;

	> * + * {
		margin-top: 1rem;
	}

	&:last-child {
		&:first-child {
			margin-bottom: 0;
		}
	}

	&.info {
		border-color: $color-bg;
		color: $color-text;
		background-color: $color-blue-bg;
	}

	&.success {
		border-color: $color-success;
		color: $color-success;
		background-color: $color-success-bg;
	}
	
	&.error {
		border-color: $color-error;
		color: $color-error;
		background-color: $color-error-bg;
	}
}